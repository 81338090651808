<template>
	<v-dialog v-model="dialogValue" max-width="1024" scrollable>
		<v-card>
			<v-card-title>
				<v-icon left>lock</v-icon>Cadastro de Usuário
				<v-spacer></v-spacer>
				<v-btn icon @click="hideDialog">
					<v-icon>close</v-icon>
				</v-btn>
			</v-card-title>
			<v-card-text>
				<v-text-field
					v-model="usuario.nome"
					label="Nome"
					prepend-icon="person"
					type="text"
					required
					:disabled="loading"
				/>
				<v-select
					prepend-icon="location_city"
					label="Cidade"
					v-model="usuario.cidade"
					:items="cidades"
					item-text="nome"
					item-value="nome"
					:disabled="loading"
				></v-select>
				<v-text-field
					v-model="usuario.email"
					label="E-mail"
					prepend-icon="email"
					type="email"
					required
					:disabled="loading"
				/>
				<v-row v-if="!usuario.id">
					<v-col>
						<v-text-field
							v-model="senha"
							label="Senha"
							prepend-icon="lock"
							:type="show ? 'text' : 'password'"
							required
							:disabled="loading"
						>
							<template v-slot:append>
								<v-icon color="primary" @click="show = !show">
									{{ show ? 'mdi-eye' : 'mdi-eye-off' }}
								</v-icon>
							</template>
						</v-text-field>
					</v-col>
					<v-col>
						<v-text-field
							v-model="confirmaSenha"
							label="Confirmação Senha"
							:prepend-icon="senha === confirmaSenha ? 'lock' : 'lock'"
							:type="show2 ? 'text' : 'password'"
							required
							:disabled="loading"
						>
							<template v-slot:append>
								<v-icon color="primary" @click="show2 = !show2">
									{{ show2 ? 'mdi-eye' : 'mdi-eye-off' }}
								</v-icon>
							</template>
						</v-text-field>
					</v-col>
				</v-row>
				<v-row justify="space-between" class="pl-2 pr-2">
					<v-checkbox
						label="Ativo"
						v-model="ativo"
						:disabled="loading"
						hide-details=""
						v-if="userAdmin"
						class="ma-0"
					></v-checkbox>
					<v-checkbox
						label="Admin"
						v-model="admin"
						:disabled="loading"
						hide-details=""
						class="ma-0"
						v-if="userMaster"
					></v-checkbox>
					<v-checkbox
						label="Relatórios"
						v-model="usuario.relatorios"
						:disabled="loading"
						hide-details=""
						class="ma-0"
						v-if="userMaster"
					></v-checkbox>
					<v-checkbox
						label="PxAcertos"
						v-model="usuario.pxacertos"
						:disabled="loading"
						hide-details=""
						class="ma-0"
						v-if="userMaster"
					></v-checkbox>
					<v-checkbox
						label="Regras Trib."
						v-model="usuario.regrastrib"
						:disabled="loading"
						hide-details=""
						class="ma-0"
						v-if="userMaster"
					></v-checkbox>
					<v-checkbox
						label="Mercado Pago"
						v-model="usuario.mercadopago"
						:disabled="loading"
						hide-details=""
						class="ma-0"
						v-if="userMaster"
					></v-checkbox>
				</v-row>
				<v-row justify="space-between" class="pl-2 pr-2">
					<v-checkbox
						label="Integrações"
						v-model="usuario.integracoes"
						:disabled="loading"
						hide-details=""
						class="ma-0"
						v-if="userMaster"
					></v-checkbox>
					<v-checkbox
						label="Aplicativos"
						v-model="usuario.aplicativos"
						:disabled="loading"
						hide-details=""
						class="ma-0"
						v-if="userMaster"
					></v-checkbox>
					<v-checkbox
						label="Relatórios Padrões"
						v-model="usuario.relatoriosPadroes"
						:disabled="loading"
						hide-details=""
						class="ma-0"
						v-if="userMaster"
					></v-checkbox>
					<v-checkbox
						label="Consultar CPF"
						v-model="usuario.consultacpf"
						:disabled="loading"
						hide-details=""
						class="ma-0"
						v-if="userMaster"
					></v-checkbox>
					<v-checkbox
						label="Plug4Market"
						v-model="usuario.plugmarket"
						:disabled="loading"
						hide-details=""
						class="ma-0"
						v-if="userMaster"
					></v-checkbox>
					<v-checkbox
						label="Consulta Bloqueios"
						v-model="usuario.bloqueios"
						:disabled="loading"
						hide-details=""
						class="ma-0"
						v-if="userMaster"
					></v-checkbox>
				</v-row>

				<v-card class="pa-3 mt-3">
					<v-card-title class="pa-0">Registro de Terminal</v-card-title>
					<div class="d-flex justify-space-between">
						<div>
							<v-checkbox
								label="Gerar Token"
								v-model="usuario.usuTerminalTokenIncluir"
								:disabled="loading"
								hide-details=""
								class="ma-0"
								v-if="userMaster"
							></v-checkbox>
							<v-checkbox
								label="Desativar Token"
								v-model="usuario.usuTerminalTokenExcluir"
								:disabled="loading"
								hide-details=""
								class="ma-0"
								v-if="userMaster"
							></v-checkbox>
						</div>

						<div>
							<v-checkbox
								label="Incluir Empresa"
								v-model="usuario.usuTerminalEmpresaIncluir"
								:disabled="loading"
								hide-details=""
								class="ma-0"
								v-if="userMaster"
							></v-checkbox>
							<v-checkbox
								label="Excluir Impresa"
								v-model="usuario.usuTerminalEmpresaExcluir"
								:disabled="loading"
								hide-details=""
								class="ma-0"
								v-if="userMaster"
							></v-checkbox>
						</div>

						<div>
							<v-checkbox
								label="Incluir Conexão"
								v-model="usuario.usuTerminalConexaoIncluir"
								:disabled="loading"
								hide-details=""
								class="ma-0"
								v-if="userMaster"
							></v-checkbox>
							<v-checkbox
								label="Excluir Conexão"
								v-model="usuario.usuTerminalConexaoExcluir"
								:disabled="loading"
								hide-details=""
								class="ma-0"
								v-if="userMaster"
							></v-checkbox>
						</div>

						<div>
							<v-checkbox
								label="Incluir Database"
								v-model="usuario.usuTerminalDbIncluir"
								:disabled="loading"
								hide-details=""
								class="ma-0"
								v-if="userMaster"
							></v-checkbox>
							<v-checkbox
								label="Excluir Database"
								v-model="usuario.usuTerminalDbExcluir"
								:disabled="loading"
								hide-details=""
								class="ma-0"
								v-if="userMaster"
							></v-checkbox>
						</div>

						<div>
							<v-checkbox
								label="Alterar Qt. Máquinas"
								v-model="usuario.usuTerminalQtmaquinasModificar"
								:disabled="loading"
								hide-details=""
								class="ma-0"
								v-if="userMaster"
							></v-checkbox>
						</div>
					</div>
				</v-card>

				<v-row v-if="usuario.id">
					<v-col cols="2" v-if="userMaster">
						<v-btn color="primary" @click="alterarSenha()"> Alterar senha </v-btn>
					</v-col>
				</v-row>
			</v-card-text>
			<v-card-actions>
				<v-progress-linear v-if="loading" indeterminate color="primary"></v-progress-linear>
				<v-spacer v-if="!loading"></v-spacer>
				<v-btn v-if="!loading" @click="sair">Sair</v-btn>
				<v-btn color="primary" v-if="!loading" @click="salvar">Salvar</v-btn>
			</v-card-actions>
			<RedefinirSenha :abrirDialog="abrir" @dialogClose="closeAlterarSenha" :id="usuario.id" tipo="usuario" />
		</v-card>
	</v-dialog>
</template>
<script>
import { mapMutations, mapState, mapActions, mapGetters } from 'vuex'
import RedefinirSenha from './RedefinirSenha.vue'

export default {
	data: function () {
		return {
			loading: false,
			cidades: [
				{ uf: 'GO', nome: 'Jataí' },
				{ uf: 'GO', nome: 'Rio Verde' },
				{ uf: 'TO', nome: 'Palmas' },
			],
			abrir: false,
			senha: '',
			confirmaSenha: '',
			show: false,
			show2: false,
		}
	},
	components: {
		RedefinirSenha,
	},
	computed: {
		...mapGetters(['userAdmin', 'userMaster']),
		...mapState('usuarios', ['usuario', 'dialog']),
		dialogValue: {
			get() {
				return this.dialog
			},
			set(val) {
				this.setDialog(val)
			},
		},
		ativo: {
			get() {
				return this.usuario.ativo ?? true
			},
			set(val) {
				this.usuario.ativo = val
				this.setAtivo()
			},
		},
		admin: {
			get() {
				return this.usuario.admin
			},
			set(val) {
				this.usuario.admin = val
				this.setAdmin()
			},
		},
	},
	methods: {
		...mapMutations('usuarios', ['setDialog']),
		...mapActions('usuarios', ['hideDialog']),

		async salvar() {
			if (this.usuario.id) {
				this.editar()
			} else {
				this.criar()
			}
		},
		async editar() {
			try {
				// eslint-disable-next-line no-console
				//console.log(this.axios.defaults.baseURL);

				this.loading = true
				await this.axios.put(`/usuarios/${this.usuario.id}`, this.usuario)
				this.$store.dispatch('showSuccess', 'Usuário alterado com sucesso')
				this.hideDialog()
				this.$emit('closeModal')
			} catch (error) {
				this.$store.dispatch('showError', error)
			} finally {
				this.loading = false
			}
		},
		async criar() {
			if (!(this.senha == this.confirmaSenha)) this.$store.dispatch('showError', 'As senhas não estão iguais!')
			else {
				try {
					this.loading = true
					this.usuario.ativo = this.ativo
					this.usuario.admin = this.admin
					await this.axios.post(`/usuarios`, this.usuario)
					this.$store.dispatch('showSuccess', 'Usuário criado com sucesso!')
					this.hideDialog()
					this.$emit('closeModal')
				} catch (error) {
					this.$store.dispatch('showError', error)
				} finally {
					this.confirmaSenha = this.senha = ''
					this.loading = false
				}
			}
		},

		async setAtivo() {
			if(this.usuario.id) await this.setField('ativo', 'Ativo alterado com sucesso!')
		},
		async setAdmin() {
			if(this.usuario.id) await this.setField('admin', 'Admin alterado com sucesso!')
		},
		async setField(field, msg) {
			try {
				this.loading = true
				const params = {}
				params[field] = this.usuario[field]
				await this.axios.put(`/usuarios/${this.usuario.id}/${field}`, params)
				this.$store.dispatch('showSuccess', msg)
			} catch (error) {
				this.$store.dispatch('showError', error)
			} finally {
				this.loading = false
			}
		},
		sair() {
			this.hideDialog()
		},
		alterarSenha() {
			this.abrir = true
		},
		closeAlterarSenha() {
			this.abrir = false
		},
	},
}
</script>