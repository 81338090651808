import Vue from 'vue'
import Vuex from 'vuex'

import usuarios from './modules/usuarios'

Vue.use(Vuex)

const store = new Vuex.Store({
	modules: { usuarios },
	state: {
		user: null,
		drawer: true,
		userKey: '__user',
		alert: {}
	},
	getters: {
		user: state => {
			return state.user
		},
		userAtivo: state => {
			return state.user !== null && state.user.ativo === true
		},
		userAdmin: state => {
			return state.user !== null && state.user.admin === true
		},
		userMaster: state => {
			return state.user !== null && state.user.master === true
		},
		userRegrasTrib: state => {
			return state.user !== null && state.user.regrastrib === true
		},
		userRelatorios: state => {
			return state.user !== null && state.user.relatorios === true
		},
		userPxAcertos: state => {
			return state.user !== null && state.user.pxacertos === true
		},
		userIntegracoes: state => {
			return state.user !== null && state.user.integracoes == true
		},
		userAplicativos: state => {
			return state.user !== null && state.user.aplicativos == true
		},
		userRelatoriosPadroes: state => {
			return state.user !== null && state.user.relatoriosPadroes == true
		},
		userConsultacpf: state => {
			return state.user !== null && state.user.consultacpf == true
		},
		userPlugmarket: state => {
			return state.user !== null && state.user.plugmarket == true
		},
		userMercadopago: state => {
			return state.user !== null && state.user.mercadopago == true
		},
		userBloqueios: state => {
			return state.user !== null && state.user.bloqueios == true
		}
	},
	mutations: {
		toggleDrawer(state) {
			state.drawer = !state.drawer
		},
		setDrawer(state, drawer) {
			state.drawer = drawer
		},
		setUser(state, user) {
			state.user = user
		},
		setAlert(state, alert) {
			state.alert = alert
		}
	},
	actions: {
		login({ commit, state }, user) {
			commit('setUser', user)
			localStorage.setItem(state.userKey, JSON.stringify(user))
			Vue.axios.defaults.headers.common['Authorization'] = `Bearer ${user.token}`
		},
		logout({ commit, state }) {
			commit('setUser', null)
			localStorage.removeItem(state.userKey)
		},
		showError({ commit }, error) {
			let msg = ''
			if (typeof error === 'string') msg += error
			else if (error.response.data.detalhes) {
				msg += error.response.data.detalhes
			} else if (error.response.data.msg) {
				msg += error.response.data.msg
			} else {
				msg += error.message
			}
			commit('setAlert', { color: 'error', msg, visible: true })
		},
		showSuccess({ commit }, msg) {
			commit('setAlert', { color: 'success', msg, visible: true })
		}
	}
})

export default store
