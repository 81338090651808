<template>
	<div>
		<UsuarioDialog @closeModal="loadUsuarios" />
		<v-card>
			<v-card-title> <v-icon left color="primary">lock</v-icon>Cadastro de Usuários </v-card-title>
			<v-card-text>
				<v-row>
					<v-col cols="12" md="9">
						<v-text-field
							v-model="search"
							append-icon="search"
							label="Pesquisar pelo Nome ou email"
							single-line
							hide-details
						></v-text-field>
					</v-col>
					<v-col  cols="12" md="3">
						<v-btn
							v-if="userAdmin"
							class="white--text"
							color="primary"
							elevation="9"
							width="100%"
							@click="usuarioSelected()"
							large
						>
							<v-icon left> mdi-plus </v-icon>
							Cadastrar Usuário
						</v-btn>
					</v-col>
				</v-row>
				<v-row>
					<v-col cols="12" sm="6" md="1">
						<v-checkbox v-model="desativados" label="Desativados?"></v-checkbox>
					</v-col>
				</v-row>
				<v-data-table
					:headers="headers"
					:items="usuarios"
					class="elevation-1"
					:loading="loading"
					disable-pagination
					hide-default-footer
					@click:row="usuarioSelected"
				>
					<template v-slot:[`item.admin`]="{ item }">
						<v-icon color="success" v-if="item.admin">check_circle_outline</v-icon>
					</template>
					<template v-slot:[`item.ativo`]="{ item }">
						<v-icon color="success" v-if="item.ativo">check_circle_outline</v-icon>
					</template>
					<template v-slot:[`item.relatorios`]="{ item }">
						<v-icon color="success" v-if="item.relatorios">check_circle_outline</v-icon>
					</template>
					<template v-slot:[`item.pxacertos`]="{ item }">
						<v-icon color="success" v-if="item.pxacertos">check_circle_outline</v-icon>
					</template>
					<template v-slot:[`item.regrastrib`]="{ item }">
						<v-icon color="success" v-if="item.regrastrib">check_circle_outline</v-icon>
					</template>
					<template v-slot:[`item.integracoes`]="{ item }">
						<v-icon color="success" v-if="item.integracoes">check_circle_outline</v-icon>
					</template>
					<template v-slot:[`item.aplicativos`]="{ item }">
						<v-icon color="success" v-if="item.aplicativos">check_circle_outline</v-icon>
					</template>
					<template v-slot:[`item.relatoriosPadroes`]="{ item }">
						<v-icon color="success" v-if="item.relatoriosPadroes">check_circle_outline</v-icon>
					</template>
				</v-data-table>
			</v-card-text>
		</v-card>
	</div>
</template>
<script>
import { mapMutations, mapActions, mapGetters } from 'vuex'
import UsuarioDialog from '../components/usuarios/UsuarioDialog'

export default {
	components: { UsuarioDialog },
	data: function () {
		return {
			search: '',
			usuarios: [],
			headers: [
				{ text: 'Nome', value: 'nome' },
				{ text: 'E-mail', value: 'email' },
				{ text: 'Cidade', value: 'cidade' },
				{ text: 'Admin', value: 'admin', align: 'center'  },
				{ text: 'Relatórios?', value: 'relatorios', align: 'center'  },
				{ text: 'PxAcertos?', value: 'pxacertos', align: 'center'  },
				{ text: 'Regras Trib.?', value: 'regrastrib', align: 'center' },
				{ text: 'Integrações?', value: 'integracoes', align: 'center' },
				{ text: 'Aplicativos?', value: 'aplicativos', align: 'center' },
				{ text: 'Relatórios Padrões?', value: 'relatoriosPadroes', align: 'center' },
				{ text: 'Ativo', value: 'ativo', align: 'center'  },
			],
			loading: false,
			id: '',
			desativados: false
		}
	},
	methods: {
		...mapMutations('usuarios', ['setUsuario']),
		...mapActions('usuarios', ['showDialog']),
		async loadUsuarios() {
			try {
				this.loading = true
				const response = await this.axios.get('/usuarios', {
					params: { 
						search: this.search,
						desativados: this.desativados
					},
				})
				this.usuarios = response.data
			} catch (error) {
				this.$store.dispatch('showError', error)
			} finally {
				this.loading = false
			}
		},
		usuarioSelected(usuario) {
			this.setUsuario({ ...usuario })
			this.showDialog()
		},
	},
	mounted() {
		this.loadUsuarios()
	},
	watch: {
		search() {
			this.loadUsuarios()
		},
		desativados(){
			this.loadUsuarios()
		}
	},
	computed: {
		...mapGetters(['userAdmin', 'userMaster']),
	},
}
</script>