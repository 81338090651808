import axios from 'axios';

export const handleErrors = (error) => {
    if (error.response) {
        const status = error.response.status;

        if (status === 400) {
            // console.error('Erro de validação:', error.message);
        } else if (status === 401) {
            // console.error('Não autorizado:', error.message);
        } else if (status === 500) {
            return Promise.reject('Erro no servidor - 500');
        } else {
            // console.error(`Erro ${status}:`, error.message);
        }

        if (error.response.data.errors) {
            const errorMessage = error.response.data.errors.join(", ");
            return Promise.reject(errorMessage);
        }
    } else if (error.request) {
        // eslint-disable-next-line no-console
        console.log(error.request);
        return Promise.reject('Sem resposta do servidor');
    } else {
        // console.error('Erro:', error.message);
    }

    return Promise.reject('error');
};

export class ApiService {
    // static BASE_URL = 'https://example.com/api';
    // static EMPRESAS_URL = `${ApiService.BASE_URL}/empresas`;

    constructor(baseURL) {
        const user = JSON.parse(localStorage.getItem('__user'));

        // eslint-disable-next-line no-console
        // console.log(user.token);

        let token = "";
        // check if exists resource user->token
        if (user && user.token)
            token = user.token;

        this.api = axios.create({
            baseURL: baseURL,
            headers: {
                'Authorization': `Bearer ` + token
            }
        });

        // Configurar o interceptador de resposta
        this.api.interceptors.response.use(
            response => response,
            error => handleErrors(error)
        );
    }

    service() {
        return this.api;
    }

    get(url, config = {}) {
        // return this.api.get(url, config);

        const user = JSON.parse(localStorage.getItem('__user'));

        return this.api.get(url, {
            ...config,
            headers: {
                ...config.headers,
                'Authorization': `Bearer ${user.token}`
            }
        });
    }

    post(url, data, config = {}) {
        // return this.api.post(url, data, config);
        const user = JSON.parse(localStorage.getItem('__user'));

        return this.api.post(url, data, {
            ...config,
            headers: {
                ...config.headers,
                'Authorization': `Bearer ${user.token}`
            }
        });
    }

    put(url, data = {}, config = {}) {
        // return this.api.put(url, data, config);

        const user = JSON.parse(localStorage.getItem('__user'));

        return this.api.put(url, data, {
            ...config,
            headers: {
                ...config.headers,
                'Authorization': `Bearer ${user.token}`
            }
        });
    }

    patch(url, data = {}, config = {}) {

        // return this.api.patch(url, data, config);

        const user = JSON.parse(localStorage.getItem('__user'));

        return this.api.patch(url, data, {
            ...config,
            headers: {
                ...config.headers,
                'Authorization': `Bearer ${user.token}`
            }
        });
    }

    delete(url, config = {}) {
        // return this.api.delete(url, config);

        const user = JSON.parse(localStorage.getItem('__user'));

        return this.api.delete(url, {
            ...config,
            headers: {
                ...config.headers,
                'Authorization': `Bearer ${user.token}`
            }
        });
    }
}
