<template>
	<div>
		<ClienteDialog :dialog="dialog" :clienteId="clienteId" @dialogClose="dialogClose" />
		<v-card>
			<v-card-title> <v-icon left color="primary">mdi-cancel</v-icon>Consulta Bloqueios</v-card-title>
			<v-card-text>
				<v-row>
					<v-col cols="12" sm="12" md="6">
						<v-text-field
							v-model="search"
							append-icon="search"
							label="Pesquisar pelo Nome, Nome Fantasia ou CNPJ"
							single-line
							hide-details
						/>
					</v-col>
					<v-col cols="12" sm="12" md="3">
						<v-menu
							ref="menu"
							transition="scale-transition"
							v-model="menu"
							offset-y
							:close-on-content-click="false"
						>
							<template v-slot:activator="{ on, attrs }">
								<v-text-field
									label="Data Bloqueio"
									v-model="dataBloqueioFormatada"
									append-icon="mdi-calendar"
									clearable
									clear-icon="mdi-close"
									@click:clear="dataBloqueio = dataBloqueioFormatada = []"
									v-bind="attrs"
									v-on="on"
									hide-details
								></v-text-field>
							</template>
							<v-date-picker v-model="dataBloqueio" no-title range locale="pt-br" scrollable>
								<v-spacer></v-spacer>
								<v-btn text color="primary" @click="menu = false"> Cancelar </v-btn>
								<v-btn text color="primary" @click="filtrar()"> Filtrar </v-btn>
							</v-date-picker>
						</v-menu>
					</v-col>
					<v-spacer />
					<v-col>
						<downloadexcel
							class="v-btn v-btn--contained theme--light v-size--default primary mt-3"
							:data="JSON.parse(JSON.stringify(clientes))"
							:fields="fieldsExcel"
							worksheet="relatorio"
							name="relatorio-versoes.xls"
						>
							DOWNLOAD EXCEL
						</downloadexcel>
					</v-col>
				</v-row>
				<v-row>
					<v-col cols="12" sm="6" md="2">
						<v-checkbox v-model="semContas" label="Sem contas"></v-checkbox>
					</v-col>
					<v-col cols="12" sm="6" md="2">
						<v-checkbox v-model="somenteMatriz" label="É matriz"></v-checkbox>
					</v-col>
				</v-row>
				<v-data-table
					:headers="headers"
					:items="clientes"
					:options.sync="options"
					:server-items-length="total"
					class="elevation-1"
					:loading="loading"
					dense
					@click:row="clienteSelected"
				>
					<template v-slot:[`item.dataBloqueio`]="{ item }">
						{{ convertDate(item.dataBloqueio) }}
					</template>
				</v-data-table>
			</v-card-text>
		</v-card>
	</div>
</template>
<script>
import downloadexcel from 'vue-json-excel'
import { mapGetters } from 'vuex'
import ClienteDialog from '@/components/clientes/ClienteDialog'

export default {
	components: {
		downloadexcel,
		ClienteDialog,
	},
	data: function () {
		return {
			clientes: [],
			headers: [
				{ text: 'N.Fantasia', value: 'nomeFantasia', sortable: false },
				{ text: 'N.Razão', value: 'nome', sortable: false },
				{ text: 'CNPJ', value: 'cnpj', sortable: false },
				{ text: 'Data de Bloqueio', value: 'dataBloqueio', sortable: false },
			],
			fieldsExcel: {
				NOMERAZAO: 'nome',
				NOMEFANTASIA: 'nomeFantasia',
				CNPJ: 'cnpj',
				DATABLOQUEIO: {
					field: 'dataBloqueio',
					callback: (value) => {
						return this.convertDate(value) + ' ' + this.convertTime(value)
					},
				},
			},
			search: '',
			dataBloqueio: [],
			dataBloqueioFormatada: '',
			loading: false,
			options: {},
			total: 0,
			dialog: false,
			dataPicker: false,
			clienteId: '',
			menu: false,
			somenteMatriz: false,
			semContas: false
		}
	},
	computed: {
		...mapGetters(['userAdmin', 'userMaster']),
	},
	methods: {
		async loadClientes() {
			try {
				this.loading = true
				const { page, itemsPerPage } = this.options

				const response = await this.axios.get('/bloqueios', {
					params: {
						page,
						limit: itemsPerPage,
						search: this.search,
						dataInicial: this.dataBloqueio[0],
						dataFinal: this.dataBloqueio[1] ? this.dataBloqueio[1] : this.dataBloqueio[0],
						somenteMatriz: this.somenteMatriz,
						semContas: this.semContas
					},
				})
				this.clientes = response.data.clientes
				this.total = response.data.total
			} catch (error) {
				this.$store.dispatch('showError', error)
			} finally {
				this.loading = false
			}
		},

		async clienteSelected(cliente) {
			this.dialog = true
			this.clienteId = cliente.id
		},
		dialogClose() {
			this.clienteId = ''
			this.dialog = false
			this.loadClientes()
		},
		converterData(date) {
			const [year, month, day] = date.split('-')
			const separador = '/'

			return day + separador + month + separador + year
		},
		loadClientesSearch() {
			this.options.page = 1
			this.loadClientes()
		},
		filtrar() {
			const d1 = new Date(this.dataBloqueio[0])
			const d2 = new Date(this.dataBloqueio[1])

			if (this.dataBloqueio.length == 1) {
				this.dataBloqueioFormatada = this.converterData(this.dataBloqueio[0])
			} else if (this.dataBloqueio.length == 2) {
				if (d1.getTime() < d2.getTime())
					this.dataBloqueioFormatada =
						this.converterData(this.dataBloqueio[0]) + ' - ' + this.converterData(this.dataBloqueio[1])
				else {
					this.$store.dispatch('showError', 'A data final não pode ser inferior a data inicial')
					this.dataBloqueio = []
					this.dataBloqueioFormatada = ''
					return ''
				}
			}
			this.$refs.menu.save(this.dataBloqueio)
			this.loadClientes()
		},
		setDataAtual() {
			const dataAtual = new Date()
			this.dataBloqueio = [
				dataAtual.getFullYear() +
					'-' +
					(dataAtual.getMonth() + 1).toString().padStart(2, '0') +
					'-' +
					dataAtual.getDate().padStart(2, '0'),
			]
			this.dataBloqueioFormatada = this.converterData(this.dataBloqueio[0])
		},
	},
	mounted() {
		this.setDataAtual()
		this.loadClientes()
	},
	watch: {
		options: {
			handler() {
				this.loadClientes()
			},
			deep: true,
		},
		search() {
			this.loadClientesSearch()
		},
		menu() {
			if (this.menu) {
				this.dataBloqueio = []
				this.dataBloqueioFormatada = ''
			}
		},
		somenteMatriz() {
			this.loadClientesSearch()
		},
		semContas(){
			this.loadClientes()
		}
	},
}
</script>
