import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '../views/Home.vue'
import Signin from '../views/Signin.vue'
import Signup from '../views/Signup.vue'
import Usuarios from '../views/Usuarios.vue'
import Admin from '../views/Admin.vue'
import Clientes from '../views/Clientes.vue'
import Mensagens from '../views/Mensagens.vue'
import Config from '../views/Config.vue'
import Computadores from '../views/Computadores.vue'
import Relatorios from '../views/Relatorios.vue'
import PxAcertos from '../views/PxAcertos.vue'
import Pix from '../views/Pix.vue'
import NotaSegura from '../views/NotaSegura.vue'
import Boletos from '../views/Boletos.vue'
import Integracoes from '../views/Integracoes.vue'
import RelatoriosPadroes from '../views/RelatoriosPadroes.vue'
import RegistroTerminal from '../views/RegistroTerminal.vue'
import ConsultaBloqueios from '../views/ConsultaBloqueios.vue'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'Home',
		component: Home
	},
	{
		path: '/clientes',
		name: 'Clientes',
		component: Clientes
	},
	{
		path: '/registro-terminal',
		name: 'Registro de Terminal',
		component: RegistroTerminal
	},
	{
		path: '/admin',
		name: 'Admin',
		component: Admin
	},
	{
		path: '/signin',
		name: 'Signin',
		component: Signin
	},
	{
		path: '/signup',
		name: 'Signup',
		component: Signup
	},
	{
		path: '/usuarios',
		name: 'Usuarios',
		component: Usuarios
	},
	{
		path: '/mensagens',
		name: 'Mensagens',
		component: Mensagens
	},
	{
		path: '/computadores',
		name: 'Computadores',
		component: Computadores
	},
	{
		path: '/config',
		name: 'Config',
		component: Config
	},
	{
		path: '/relatorios',
		name: 'Relatorios',
		component: Relatorios
	},
	{
		path: '/pxacertos',
		name: 'PxAcertos',
		component: PxAcertos
	},
	{
		path: '/pix',
		name: 'Pix',
		component: Pix
	},
	{
		path: '/notasegura',
		name: 'NotaSegura',
		component: NotaSegura
	},
	{
		path: '/boletos',
		name: 'Boletos',
		component: Boletos
	},
	{
		path: '/integracoes',
		name: 'Integracoes',
		component: Integracoes
	},
	{
		path: '/relatoriospadroes',
		name: 'RelatoriosPadroes',
		component: RelatoriosPadroes
	},
	{
		path: '/consultabloqueios',
		name: 'ConsultaBloqueios',
		component: ConsultaBloqueios
	}
	// {
	// 	path: '/about',
	// 	name: 'About',
	// 	// route level code-splitting
	// 	// this generates a separate chunk (about.[hash].js) for this route
	// 	// which is lazy-loaded when the route is visited.
	// 	component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
	// }
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})
export default router
