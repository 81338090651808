<template>
	<v-dialog v-model="clienteDialog" :max-width="400" scrollable>
		<v-card>
			<v-progress-linear v-if="loading" indeterminate color="primary"></v-progress-linear>
			<v-card-title class="pb-0">
				<v-icon left>mdi-magnify</v-icon>
				Consulta CPF
				<v-spacer></v-spacer>
				<v-btn icon @click="dialogClose">
					<v-icon>close</v-icon>
				</v-btn>
			</v-card-title>
			<v-card-text>
				<v-checkbox
					label="Pode consultar CPF?"
					v-model="consultacpf"
					:disabled="!cliente.ativo"
					:hide-details="consultacpfMinhaconta"
				/>
				<div v-if="!consultacpfMinhaconta">
					<v-text-field
						v-model="consultacpfQtmin"
						label="Quantidade de consultas permitidas esse mês"
						type="number"
					/>
					<v-text-field
						v-model="cliente.consultacpfQtmes"
						label="Quantidade de consultas realizadas esse mês"
						readonly
					/>
				</div>
				<v-checkbox
					label="Fazer consultas com conta própria?"
					hide-details
					v-model="consultacpfMinhaconta"
					:disabled="!cliente.ativo"
					v-if="consultacpf"
				/>
				<div v-if="consultacpf && consultacpfMinhaconta">
					<v-col class="pl-0">
						<p class="mb-0 label">Tipo de consulta:</p>
						<v-radio-group v-model="apibrasilTipo" row class="mt-0" hide-details>
							<v-radio label="Plano Contratado" :value="'0'" />
							<v-radio label="Por Crédito" :value="'1'" />
						</v-radio-group>
					</v-col>
					<v-text-field v-model="apibrasilToken" label="Token API Brasil" />
					<v-text-field v-model="apibrasilDeviceToken" label="Token Dispositivo" hide-details />
				</div>
				<div class="d-flex flex-row-reverse">
					<v-btn color="primary" class="mt-4" @click="salvar">Salvar</v-btn>
				</div>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>
<script>
import { mapGetters, mapState } from 'vuex'

export default {
	props: {
		cliente: Object,
		dialog: Boolean,
	},
	data: function () {
		return {
			loading: false,
			consultacpf: '',
			consultacpfQtmin: '',
			consultacpfMinhaconta: '',
			apibrasilToken: '',
			apibrasilDeviceToken: '',
			apibrasilTipo: '',
		}
	},
	computed: {
		...mapGetters(['userAdmin', 'userMaster']),
		...mapState(['user']),
		clienteDialog: {
			get() {
				return this.dialog
			},
			set() {
				this.dialogClose()
			},
		},
	},
	methods: {
		dialogClose() {
			this.$emit('dialogClose')
		},
		loadCliente() {
			this.$emit('loadCliente')
		},
		async salvar() {
			try {
				this.loading = true
				await this.axios.put(`/clientes/${this.cliente.id}/consultacpf`, {
					consultacpf: this.consultacpf,
					consultacpfQtmin: this.consultacpfQtmin,
					consultacpfMinhaconta: this.consultacpfMinhaconta,
					apibrasilToken: this.apibrasilToken,
					apibrasilDeviceToken: this.apibrasilDeviceToken,
					apibrasilTipo: this.apibrasilTipo,
				})
				this.$store.dispatch('showSuccess', 'Quantidade alterada!')
				this.dialogClose()
			} catch (error) {
				this.$store.dispatch('showError', error)
			} finally {
				this.loading = false
			}
		},
	},
	watch: {
		cliente() {
			if (this.clienteDialog) {
				this.consultacpf = this.cliente.consultacpf
				this.consultacpfQtmin = this.cliente.consultacpfQtmin
				this.consultacpfMinhaconta = this.cliente.consultacpfMinhaconta
				this.apibrasilToken = this.cliente.apibrasilToken
				this.apibrasilDeviceToken = this.cliente.apibrasilDeviceToken
				this.apibrasilTipo = this.cliente.apibrasilTipo.toString()
			}
		},
	},
}
</script>