import { ApiService } from './common/serviceBase';
import { URL_CAD_REGISTRO_TERMINAL } from './common/apiUrl';

const service = new ApiService(`${URL_CAD_REGISTRO_TERMINAL}/rttokens/`);

export async function getAll(idDb, params) {
    const response = await service.get(idDb, {
        params: params
    });
    return response.data;
}

export async function create(token) {
    const response = await service.post('', token);
    return response;
}

export async function desativar(token) {
    const response = await service.put(token + '/desativar');
    return response;
}

export async function ipLivre(token){
    const response = await service.put(token + '/iplivre');
    return response;
}